import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from '@firebase/app-compat';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, find, map, repeatWhen, shareReplay, take, tap } from 'rxjs/operators';
import { Empresa } from 'src/app/shared/models/empresa.model';
import { Rol } from 'src/app/shared/models/rol-enum';
import { User } from './user';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public user$: BehaviorSubject<any> = new BehaviorSubject(null);

  private empresaIdGlobalSubject = new BehaviorSubject<Empresa>(null);
  public empresaSelGlobal$ = this.empresaIdGlobalSubject.asObservable().pipe(
    filter((data) => data !== null),
    distinctUntilChanged()
  );

  public empresaMaster$ = this.afs
    .collection('-configuracion')
    .doc<{ id: string; nombre: string }>('empresa-master')
    .valueChanges();

  public esMaster$ = combineLatest([this.user$, this.empresaMaster$]).pipe(
    map(([usuario, empresaMaster]) => {
      if (usuario && usuario.empresa && usuario.empresa.id === empresaMaster.id) {
        return true;
      } else {
        return false;
      }
    }),
    shareReplay(1)
  );

  userData: any;
  credentials = { username: '', password: '' };
  errorMessage: string;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private snackbar: MatSnackBar
  ) {
    // console.log('AuthService', NgZone.isInAngularZone());

    this.afAuth.onAuthStateChanged(
      (user) => {
        console.log('onAuthStateChanged', user);
        this.ngZone.run(() => {
          // console.log('hay usuario?', user);

          if (user) {
            const usuario = this.obtenerUsuario(user.uid)
              .pipe(take(1))
              .toPromise()
              .then(async (usuario) => {
                // No puede existir mas ni menos de un usuario ligado a un authenticationId
                // console.log('NOTA DE ROD usuario', usuario)
                if (!usuario) {
                  return null;
                }

                // Definiendo valor inicial para empresa global
                if (usuario && usuario.empresa) {
                  this.actualizaEmpresaGlobal(usuario.empresa);
                }

                const claims = await this.getUserClaims();
                const admin = this.checkAuthorizationUser(usuario, ['empresa', 'master'], claims);

                if (admin) {
                  const udata = { nombre: `${usuario.nombre} ${usuario.apellidoPaterno}` };
                  this.user$.next({
                    signed: true,
                    data: user,
                    userData: udata,
                    usuario: usuario,
                    empresa: usuario.empresa,
                    nombre: udata.nombre,
                  });
                  // console.log('User Admin signed in...', this.user$.value);
                } else {
                  this.user$.next({ signed: false, data: user });
                  // RMG revisar con SAM
                  if (this.router.url.indexOf('registro') === -1) {
                    this.router.navigate(['/login']);
                  }
                }
              });
          } else {
            this.actualizaEmpresaGlobal({ id: '', nombre: '', esMaster: false } as Empresa);
            this.user$.next({ signed: false });
            // this.router.navigate(['login']);
            if (this.router.url !== '/login' && this.router.url.indexOf('registro') === -1) {
              this.router.navigate(['/login']);
            }
          }
        });
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  /**
   * Actualiza la empresa seleccionado para filtros globales.
   * @param empresa La empresa a ser seleccionada.
   */
  public actualizaEmpresaGlobal(empresa: Empresa): void {
    // console.log('actualizando empresa', empresa);
    this.empresaIdGlobalSubject.next(empresa);
  }

  /**
   * Actualiza los datos del usuario en session.
   * Toma los datos al momento de la base de datos y los integra al objeto en memoria.
   */
  public async actualizarDatosUsuarioSesion(uid: string) {
    if (uid) {
      // console.log('getvalue', this.user$.getValue())
      const usuarioSession = await this.usuarioSesion().pipe(take(1)).toPromise();
      const datosUsuario = await this.obtenerUsuario(uid).pipe(take(1)).toPromise();

      if (datosUsuario) {
        // Definiendo valor inicial para empresa global
        if (datosUsuario && datosUsuario.empresa) {
          this.actualizaEmpresaGlobal(datosUsuario.empresa);
        }

        const claims = await this.getUserClaims();
        const admin = this.checkAuthorizationUser(datosUsuario, ['empresa', 'master'], claims);

        if (admin) {
          const udata = { nombre: `${datosUsuario.nombre} ${datosUsuario.apellidoPaterno}` };
          this.user$.next({
            signed: true,
            data: usuarioSession.data,
            userData: udata,
            usuario: datosUsuario,
            empresa: datosUsuario.empresa,
            nombre: udata.nombre,
          });
          // console.log('usuario registrado es admin');
        }
      }
    }
  }

  public async resetToken() {
    await firebase.auth().currentUser.getIdToken(true);
  }

  /**
   * Retorna el usuario en sesion basado en user$.
   */
  usuarioSesion(): Observable<any> {
    return this.user$.pipe(
      tap((data) => console.log(data)),
      repeatWhen((obs) => obs),
      find((data) => data !== null)
    );
  }

  /**
   * Retorna seleccionada basada en el usuario en sesion y permisos.
   */
  empresaSesion(): Observable<Empresa> {
    return this.empresaSelGlobal$.pipe(
      tap((data) => console.log(data)),
      filter((data) => data !== null),
      distinctUntilChanged()
      // repeatWhen(obs => obs),
      // find(data => data !== null)
    );
  }

  /**
   * Verifica si el usuario actual contiene el rol proporcionado, busca en usuario.roles[ElRol].
   * @param rol Rol a verificar.
   * @returns true o false.
   */

  tieneRol(rol: Rol, claims: { [key: string]: any }): boolean {
    if (!claims.role) {
      return false;
    }

    if (!this.user$.value) {
      return false;
    }

    return claims.role === rol;
  }

  async iniciaSesionTelefono(telefono, verificationId, codigo) {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, codigo);
      const res = await this.afAuth.signInWithCredential(credential);
      console.log('resultado pop up');
      this.setUserData({}, res.user);
      console.log(res);

      // Nota 18.08.2023 Este codigo se deshabilita, debido a que usuario.roles ya no se utilizan.
      // Los claims en las rutas son los que dan acceso
      // const isAdmin = await this.isAdmin(res.user.uid);
      // if (isAdmin) {
      //   this.ngZone.run(() => {
      //     this.router.navigate(['usuarios']);
      //   });
      // } else {
      //   console.log('access denied');
      //   // this.snackbar.open('No tiene Permisos Administrador');
      // }
    } catch (error) {
      console.log('error', error);
      this.errorMessage = error.message;
    }
  }

  async registrarUsuarioSinSesionTelefono(verificationId, codigo) {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, codigo);
      const res = await this.afAuth.signInWithCredential(credential);
      console.log('Credential', credential);

      if (res) {
        const uid = res.user.uid;

        const nodoUsuario = await this.obtenerUsuario(uid).pipe(take(1)).toPromise();
        // console.log('NODO USUARIO', nodoUsuario);
        if (nodoUsuario) {
          // Quiere decir que ya existe un registro en /usuarios/ Pero hay que revisar si ya esta completo su registro
          if (nodoUsuario.registradoWeb) {
            // Ya existe, entonces avisar que ya tiene un registro y llevarlo a sesión
            this.setUserData({}, res.user);
            this.router.navigate(['obras']);
          } else {
            // sino, es probable que su registro lo dejo a la mitad, entonces hay que continuar con su registro
            return uid;
          }
        } else {
          // Sino existe objeto, entonces quiere decir que es la primera vez que  se registra ese telefono.
          // Hay que crear una entrada en usuarios con ese nuevo uid.
          // 13 / 06 / 2023 se deshabilita la siguiente linea
          // await this.afs.collection('usuarios').doc(uid).set({ registradoWeb: false });
        }
        return uid;
      }
      return null;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }

  async registrarSesionTelefono(telefono, verificationId, codigo) {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, codigo);
      const res = await this.afAuth.signInWithCredential(credential);

      if (res) {
        const uid = res.user.uid;

        const nodoUsuario = await this.obtenerUsuario(uid).pipe(take(1)).toPromise();
        // console.log('NODO USUARIO', nodoUsuario);
        if (nodoUsuario) {
          // Quiere decir que ya existe un registro en /usuarios/ Pero hay que revisar si ya esta completo su registro
          if (nodoUsuario.registradoWeb) {
            // Ya existe, entonces avisar que ya tiene un registro y llevarlo a sesión
            this.setUserData({}, res.user);
            this.router.navigate(['obras']);
          } else {
            // sino, es probable que su registro lo dejo a la mitad, entonces hay que continuar con su registro
            return uid;
          }
        } else {
          // Sino existe objeto, entonces quiere decir que es la primera vez que  se registra ese telefono.
          // Hay que crear una entrada en usuarios con ese nuevo uid.
          // TODO: Controlar este caso, es probable que ya se pueda manejar este registro.
          // 13 / 06 / 2023 se comenta esta linea, parece ser que ya no es necesario, debido a que el function ya se encarga de crear el registro en BD
          // console.error('Ya no vasir');
          // await this.afs.collection('usuarios').doc(uid).set({ registradoWeb: false });
        }
        return uid;
      }
      return null;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }

  async signout() {
    try {
      await this.afAuth.signOut();
      console.log('signout');
      this.ngZone.run(() => {
        this.router.navigate(['login']);
      });
    } catch (error) {
      console.log(error);
    }
  }

  getExtraUserdata(user) {
    return this.afs.doc(`usuarios/${user.uid}`).ref.get();
  }

  setUserData(data, user) {
    this.userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      /*firstName: data.firstName,
      lastName: data.lastName,*/
    };

    localStorage.setItem('user', JSON.stringify(this.userData));
  }

  async isLoggedIn() {
    const user = await this.afAuth.authState;
    return user !== null ? true : false;
  }

  ngOnDestroy(): void {
    console.log('destroy auth subs');
  }

  /*get user(): any {
    return JSON.parse(localStorage.getItem('user'));
  }*/

  private checkAuthorizationUser(user: User, roles: string[], claims: { [key: string]: any }): boolean {
    if (!user) {
      return false;
    }

    if (!claims.role) {
      return false;
    }

    return roles.includes(claims.role);
  }

  private obtenerUsuario(authId: string) {
    return this.afs
      .collection<User>('usuarios', (ref) => ref.where('authenticationId', '==', authId))
      .valueChanges()
      .pipe(
        map((usuarios) => {
          if (usuarios.length != 1) {
            return null;
          }
          return usuarios[0];
        })
      );
  }

  public async getUserClaims() {
    const token = await (await this.afAuth.currentUser).getIdTokenResult();
    return token.claims;
  }
}
