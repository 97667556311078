import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/security/auth.guard';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { Rol } from './shared/models/rol-enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [{ path: 'main', loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule) }],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{ path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule) }],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'registro',
        loadChildren: () => import('./pages/registro/registro.module').then((m) => m.RegistroModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      { path: 'obras', loadChildren: () => import('./pages/obras/obras.module').then((m) => m.ObrasModule) },
      {
        path: 'obras/:obraNombre/trabajadores',
        loadChildren: () => import('./pages/trabajadores/trabajadores.module').then((m) => m.TrabajadoresModule),
      },
      {
        path: 'obras/:obraNombre/solicitud-horas',
        loadChildren: () =>
          import('./pages/solicitud-horas/solicitud-horas.module').then((m) => m.SolicitudHorasModule),
      },
      {
        path: 'obras/:obraNombre/reportes',
        loadChildren: () => import('./pages/reportes/reportes.module').then((m) => m.ReportesModule),
      },
      {
        path: 'obras/:obraId/insumos',
        loadChildren: () => import('./pages/insumos-obra/insumos-obra.module').then((m) => m.InsumosObraModule),
      },
      {
        path: 'obras/:obraId/conceptos',
        loadChildren: () => import('./pages/insumos/insumos.module').then((m) => m.InsumosModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then((m) => m.UsuariosModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'configuracion-empresa',
        loadChildren: () =>
          import('./pages/configuraciones-empresa/configuraciones-empresa.module').then(
            (m) => m.ConfiguracionesEmpresaModule
          ),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'postulantes',
        loadChildren: () => import('./pages/postulantes/postulantes.module').then((m) => m.PostulantesModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'trabajadores',
        loadChildren: () => import('./pages/trabajadores/trabajadores.module').then((m) => m.TrabajadoresModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'archivos-idse',
        loadChildren: () => import('./pages/archivos-idse/archivos-idse.module').then((m) => m.ArchivosIdseModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Master] },
    children: [
      {
        path: 'calculo-salario-real',
        loadChildren: () =>
          import('./pages/calculo-salario-real/calculo-salario-real.module').then((m) => m.CalculoSalarioRealModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'solicitud-horas',
        loadChildren: () =>
          import('./pages/solicitud-horas/solicitud-horas.module').then((m) => m.SolicitudHorasModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'reportes',
        loadChildren: () => import('./pages/reportes/reportes.module').then((m) => m.ReportesModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'sandbox',
        loadChildren: () => import('./pages/ranvou-sandbox/ranvou-sandbox.module').then((m) => m.RanvouSandboxModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Master] },
    children: [
      { path: 'insumos', loadChildren: () => import('./pages/insumos/insumos.module').then((m) => m.InsumosModule) },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      {
        path: 'catalogos',
        loadChildren: () => import('./pages/catalogos/catalogos.module').then((m) => m.CatalogosModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Rol.Empresa, Rol.Master] },
    children: [
      { path: 'insumos', loadChildren: () => import('./pages/insumos/insumos.module').then((m) => m.InsumosModule) },
    ],
  },

  {
    path: '**',
    redirectTo: '/main',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
