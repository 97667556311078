import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/security/auth.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Empresa } from '../../models/empresa.model';
import { EmpresaService } from '../../services/empresa.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dpc-sidenav',
  templateUrl: './dpc-sidenav.component.html',
  styleUrls: ['./dpc-sidenav.component.scss'],
})
export class HostSidenavComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('selectEmpresas') empresasSelect: MatSelect;

  submenu = '';

  public appName = environment.name;
  public appVersion = environment.version;
  public environment = environment.production ? 'p' : 'd';
  public projectId = environment.firebaseConfig.projectId;

  public avisoCerrado = false;

  public subs = new SubSink();
  public esMaster = false;
  public empresas: Empresa[];

  public user$ = this.auth.user$;

  data$ = this.auth.esMaster$
    .pipe(
      switchMap((esMaster) => {
        this.esMaster = esMaster;
        if (!esMaster) {
          return EMPTY;
        }
        if (esMaster) {
          return this.empresasSvc.getTodasEmpresas();
        }
      })
    )
    .pipe(
      tap((empresas) => {
        this.empresas = empresas;
        // console.log(this.empresasSelect);
      })
    );

  public empresaSelGlobal$ = this.auth.empresaSelGlobal$;

  constructor(private auth: AuthService, private router: Router, private empresasSvc: EmpresaService) {}

  public onSubMenu(menu: string) {
    console.log(this.submenu, menu);
    if (this.submenu === menu) {
      this.submenu = '';
    } else {
      this.submenu = menu;
    }
  }

  public async onNavigate(path: any, e: any): Promise<void> {
    // e.stopPropagation();
    await this.router.navigate(path);
    this.sidenav.close();
  }

  ngOnInit() {
    this.subs.sink = this.data$.subscribe();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onSelectEmpresa(event: MatSelectChange) {
    this.auth.actualizaEmpresaGlobal(event.value);
    await this.router.navigate(['obras']);
  }

  onEmpresas() {
    this.empresasSelect.open();
  }

  async onCerrarSesion(): Promise<void> {
    await this.auth.signout();
  }
}
