import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { combineLatest, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Usuario } from '../models';
import { EmpleadoFijo } from '../models/empleado-fijo.model';
import { Empresa } from '../models/empresa.model';

@Injectable({
  providedIn: 'root',
})
export class EmpresaService {
  constructor(private afs: AngularFirestore) {}

  getTodasEmpresas() {
    return this.afs.collection<Empresa>('empresas').valueChanges();
  }

  getEmpresa(empresaId: string) {
    return this.afs.collection<Empresa>('empresas').doc(empresaId).valueChanges();
  }

  actualizar(empresa: Empresa) {
    this.afs
      .collection('empresas')
      .doc(empresa.id)
      .update({ ...empresa });
  }

  getEmpresas(): Promise<Empresa[]> {
    console.log('getEmpresas');
    return new Promise<Empresa[]>((resolve, reject) => {
      try {
        let empresas: Empresa[] = [];
        // TODO
        // this.afs.collection('empresas')
        //     // .collection('admin').doc('catalogos').collection('empresas')
        //     .valueChanges().pipe(take(1)).subscribe(resp => {
        //   resp.forEach(empresa => empresas.push( empresa as Empresa));
        // });
        empresas = [
          {
            id: 'dpc',
            nombre: 'DPC Constructivos SA de CV',
            // registroPatronal: '1234567890'
            // , digitoVerificadorRP: 1, fechaMovimiento: '1234567890', guia: ''
          },
        ];

        resolve(empresas);
      } catch (ex) {
        console.log(ex);
        reject(new Error(ex));
      }
    });
  }

  async guardar(empresa: Empresa) {
    if (empresa.id) {
      return this.afs
        .collection<Empresa>('empresas')
        .doc(empresa.id)
        .update({ ...empresa });
    } else {
      empresa.id = this.afs.createId();
      return this.afs
        .collection<Empresa>('empresas')
        .doc(empresa.id)
        .set({ ...empresa });
    }
  }

  async eliminar(empresa: Empresa): Promise<void> {
    // auth 2da instancia
    // let fbAuth2 = new AngularFireAuth(environment.firebaseConfig, 'fbAuth-2', PLATFORM_ID, this.zone);
    // const resp = await fbAuth2.auth.signInWithEmailAndPassword(empresa.email, empresa.password);
    // console.log(resp, fbAuth2.auth.currentUser);
    // await fbAuth2.auth.currentUser.delete();
    // fbAuth2 = null;
    return this.afs.collection<Empresa>('empresas').doc(empresa.id).delete();
  }

  getUsuariosEmpresa(idEmpresa: string) {
    return this.afs.collection<Usuario>('usuarios', (ref) => ref.where('empresa.id', '==', idEmpresa)).valueChanges();
  }

  getEmpleadosFijos(idEmpresa: string) {
    return (
      this.afs
        .collection<EmpleadoFijo>(`empresas/${idEmpresa}/empleados-fijos`)
        // , ref =>ref.where('empleadoFijo.empresa.id', '==', idEmpresa)
        .valueChanges()
    );
  }

  guardarEmpleadoFijo(empleadoFijo: EmpleadoFijo) {
    const pObservable = [];

    if (empleadoFijo.id) {
      pObservable.push(
        this.afs
          .collection<EmpleadoFijo>('usuarios')
          .doc(empleadoFijo.id)
          .update({ empleadoFijo: empleadoFijo.empleadoFijo })
      );
    } else {
      empleadoFijo.id = this.afs.createId();
    }

    pObservable.push(
      this.afs
        .collection<EmpleadoFijo>(`empresas/${empleadoFijo.empleadoFijo.empresa.id}/empleados-fijos`)
        .doc(empleadoFijo.id)
        .set({ ...empleadoFijo })
    );

    return forkJoin(pObservable).pipe(take(1)).toPromise();
  }

  eliminarEmpleadoFijo(ef: EmpleadoFijo) {
    const obs = [];
    obs.push(
      this.afs.collection<EmpleadoFijo>(`empresas/${ef.empleadoFijo.empresa.id}/empleados-fijos`).doc(ef.id).delete()
    );
    if (ef.nombre) {
      obs.push(
        this.afs.collection('usuarios').doc(ef.id).update({ empleadoFijo: firebase.firestore.FieldValue.delete() })
      );
    }
    return forkJoin(obs);
  }

  async verificarSiYaExisteUnaEmpresaConUsuariosIDS(currentUID: string, usuarioId: string): Promise<any> {
    const empresa = await this.afs
      .collection('empresas', (q) => q.where('createdUID', '==', currentUID).where('createdUsuarioId', '==', usuarioId))
      .valueChanges({ idField: 'id' })
      .pipe(take(1))
      .toPromise();

    if (empresa.length !== 0) {
      return empresa[0].id;
    } else return null;
  }

  getEmpresasyObrasParaFiltro() {
    return this.afs
      .collection('empresas')
      .snapshotChanges()
      .pipe(
        switchMap((empresas) => {
          // Mapea cada empresa a un Observable que obtiene sus obras
          const empresas$ = empresas.map((empresa) => {
            const data = empresa.payload.doc.data() as Empresa;

            let obras$ = this.afs.collection('empresas').doc(data.id).collection('obras').valueChanges();

            // Combina los datos de la empresa y sus obras en un solo objeto
            return combineLatest([of(data), obras$]).pipe(map(([empresa, obras]) => ({ ...empresa, obras })));
          });

          // Combina todos los Observables de las empresas en un solo array
          return combineLatest(empresas$);
        })
      );
  }
}
