import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum UserDialogType {
  OK = 'ok',
  YES_NO = 'si-no',
  ACCEPT_CANCEL = 'aceptar-cancelar',
  CONFIRM_DELETE = 'confirmar-eliminar',
}

export interface UserDialogData {
  titulo: string;
  mensaje: string;
  tipo: UserDialogType;
  confirmacion?: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './user-dialog.component.html',
})
export class UserDialogComponent {
  confirmacionIngresada = '';

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    if (this.data.tipo === UserDialogType.CONFIRM_DELETE && this.confirmacionIngresada !== this.data.confirmacion) {
      return;
    }
    this.dialogRef.close(true);
  }
}
