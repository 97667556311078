<mat-sidenav-container fullscreen>
  <mat-sidenav *ngIf="(user$ | async)?.signed" class="mat-elevation-z2" #sidenav mode="over">
    <mat-toolbar class="px-1">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col px-1 mat-headline-4 m-0 font-weight-light text-center">
            <img width="130" src="assets/img/ranvou/logoletras.png" />
          </div>
        </div>
      </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <!--Menu-->
    <div class="container-fluid">
      <!--menu-->
      <div class="row py-1 app-menu" (click)="onNavigate(['/obras'], $event)">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">construction</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Obras</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--menu-->
      <div class="row py-1 app-menu" (click)="onSubMenu('usuarios')">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">engineering</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Usuarios</span>
              </div>
              <div *ngIf="submenu === 'usuarios'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="submenu !== 'usuarios'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_right</mat-icon>
              </div>
            </div>
            <div
              *ngIf="esMaster"
              [hidden]="!(submenu === 'usuarios')"
              (click)="onNavigate(['/usuarios/base-de-datos'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Base de Datos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'usuarios')"
              (click)="onNavigate(['/usuarios/en-obras'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">En Obras</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--menu-->
      <div class="row py-1 app-menu" (click)="onNavigate(['/archivos-idse'], $event)">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">description</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">IDSE</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--menu-->
      <div *ngIf="esMaster && false" class="row py-1 app-menu" (click)="onNavigate(['/dashboard/master'], $event)">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">dashboard</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Dashboard</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--menu-->
      <div *ngIf="!esMaster && false" class="row py-1 app-menu" (click)="onNavigate(['/dashboard/empresa'], $event)">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">dashboard</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">eDashboard</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--EMPRESA-->
      <div class="row py-1 app-menu" (click)="onSubMenu('empresa')">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">apartment</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Empresa</span>
              </div>
              <div *ngIf="submenu === 'empresa'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="submenu !== 'empresa'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_right</mat-icon>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'empresa')"
              (click)="onNavigate(['/configuracion-empresa/datos-empresa'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Datos Empresa</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'empresa')"
              (click)="onNavigate(['/configuracion-empresa/empleados-fijos'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Empleados Fijos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'empresa')"
              (click)="onNavigate(['/configuracion-empresa/administradores-web'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Administradores Web</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--INSUMOS-->
      <div *ngIf="esMaster" class="row py-1 app-menu" (click)="onSubMenu('insumos')">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">landslide</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Insumos</span>
              </div>
              <div *ngIf="submenu === 'insumos'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="submenu !== 'insumos'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_right</mat-icon>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'insumos')"
              (click)="onNavigate(['/insumos/importar-excel'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Importar Insumos</span>
              </div>
            </div>

            <div
              [hidden]="!(submenu === 'insumos')"
              (click)="onNavigate(['/insumos/ver-conceptos-interes-maestra'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Conceptos que mostraron Interes</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'insumos')"
              (click)="onNavigate(['/insumos/catalogo-libre'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Catálogo de insumos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'insumos')"
              (click)="onNavigate(['/insumos/galeria-imagenes'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Galería de imágenes</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--CONFIGURACION-->
      <div class="row py-1 app-menu" (click)="onSubMenu('configuracion')">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">settings</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Configuración</span>
              </div>
              <div *ngIf="submenu === 'configuracion'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="submenu !== 'configuracion'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_right</mat-icon>
              </div>
            </div>
            <div
              *ngIf="esMaster"
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/configuracion-empresa/plantillas-contratos'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Plantillas de Contratos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/configuracion-empresa/contratos'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Contratos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/catalogos/etiquetas-contratos'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Etiquetas Contratos</span>
              </div>
            </div>
            <div
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/configuracion-empresa/aviso-de-privacidad'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Aviso de Privacidad</span>
              </div>
            </div>
            <div
              *ngIf="esMaster"
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/configuracion-empresa/terminos-de-uso'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Terminos de Uso</span>
              </div>
            </div>
            <div
              *ngIf="esMaster"
              [hidden]="!(submenu === 'configuracion')"
              (click)="onNavigate(['/calculo-salario-real/configuracion'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Integracion SBC Global</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--CATALOGOS-->
      <div *ngIf="esMaster && false" class="row py-1 app-menu" (click)="onSubMenu('catalogos')">
        <div class="col px-0">
          <!--opcion-->
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-auto ps-2 pe-1">
                <mat-icon class="mt-1 mx-1 icon-sm">menu_book</mat-icon>
              </div>
              <div class="col px-0 text-start">
                <span class="mat-body">Catalogos</span>
              </div>
              <div *ngIf="submenu === 'catalogos'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="submenu !== 'catalogos'" class="col px-0 text-end">
                <mat-icon class="mt-2 mx-1 icon-sm">keyboard_arrow_right</mat-icon>
              </div>
            </div>
            <div
              *ngIf="esMaster && false"
              [hidden]="!(submenu === 'catalogos')"
              (click)="onNavigate(['/catalogos/categorias'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Categorías</span>
              </div>
            </div>
            <div
              *ngIf="esMaster && false"
              [hidden]="!(submenu === 'catalogos')"
              (click)="onNavigate(['/catalogos/subcategorias'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Sub Categorías</span>
              </div>
            </div>
            <div
              *ngIf="esMaster && false"
              [hidden]="!(submenu === 'catalogos')"
              (click)="onNavigate(['/catalogos/caracteristicas'], $event)"
              class="row py-1 app-menu align-items-center">
              <div class="col-auto ps-4 pe-2 text-start">
                <span class="mat-caption">Caracteristicas</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-1">
        <div class="col">
          <p class="text-end mat-caption text-muted">
            {{ appVersion }}{{ environment }}
            <br />
            {{ projectId }}
          </p>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <!--Toolbar-->
    <mat-toolbar
      class="bc-gray border-bottom animate__animated animate__fast animate__fadeIn"
      *ngIf="user$ | async as sesion">
      <div *ngIf="sesion" class="container-fluid">
        <div class="row align-items-center">
          <div class="col-auto p-0 text-start" style="height: 24px">
            <mat-icon
              class="me-2"
              (mouseenter)="sidenav.opened ? sidenav.close() : sidenav.open()"
              fontIcon="menu"></mat-icon>
          </div>
          <div class="col-auto p-0 text-start">
            <span *ngIf="!esMaster" class="mat-body">
              <span class="mat-body" *ngIf="empresaSelGlobal$ | async as empresa">
                {{ empresa?.nombre }}
              </span>
            </span>
            <div class="d-flex align-items-center">
              <mat-form-field *ngIf="esMaster" class="p-0 opacity-0" style="z-index: -1">
                <mat-select [panelWidth]="null" (selectionChange)="onSelectEmpresa($event)" #selectEmpresas>
                  <mat-option
                    [ngClass]="{ 'c-orange': sesion.empresa.id === empresa.id }"
                    *ngFor="let empresa of empresas"
                    [value]="empresa">
                    {{ empresa.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button *ngIf="esMaster" class="px-1 position-absolute" (click)="onEmpresas()" mat-button>
                <ng-container *ngIf="empresaSelGlobal$ | async as empresa">
                  <span [ngClass]="{ 'c-orange': sesion.empresa.id === empresa.id }" class="mat-body">
                    {{ empresa?.nombre }}
                  </span>
                  <mat-icon
                    style="padding-top: 7px; padding-left: 7px"
                    [ngClass]="{ 'c-orange': sesion.empresa.id === empresa.id }">
                    arrow_drop_down
                  </mat-icon>
                </ng-container>
              </button>
            </div>
          </div>
          <div class="col-sm-auto ms-auto col p-0 text-end">
            <div class="d-flex align-items-center">
              <span class="mat-body align-self-center">{{ sesion?.data?.displayName }} {{ sesion?.data?.email }}</span>

              <div
                matRipple
                [matMenuTriggerFor]="currentUserMenu"
                class="mx-2 c-pointer bg-img-45 rounded-circle"
                [ngStyle]="{
                  background:
                    'url(' + (sesion?.usuario?.imgPerfilURL | imageSize : '100') + '), url(./assets/img/user-ph-50.jpg)'
                }"></div>

              <mat-menu #currentUserMenu="matMenu">
                <button *ngIf="false" mat-menu-item>
                  <mat-icon>settings</mat-icon>
                  <span>Perfil</span>
                </button>
                <div class="d-block d-sm-none col pb-1">
                  <span class="mat-body">{{ sesion?.data?.displayName }} {{ sesion?.data?.email }}</span>
                </div>
                <!-- <button (click)="onCerrarSesion()" mat-menu-item>
                <mat-icon>person</mat-icon>
                <span>Mi Perfil</span>
              </button> -->
                <button (click)="onCerrarSesion()" mat-menu-item>
                  <mat-icon>exit_to_app</mat-icon>
                  <span>Cerrar Sesión</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar>

    <!-- Alerts -->
    <ng-container *ngIf="user$ | async as sesion">
      <div
        class="alert alert-danger d-flex justify-content-between"
        *ngIf="sesion.signed && !sesion.usuario.emailConfirmado && !avisoCerrado">
        <span class="pt-3">
          No se ha confirmado su direccíon de correo. ({{ sesion.usuario.email }}) Por favor revise su bandeja de
          entrada para confirmarlo.
        </span>
        <button type="button" mat-icon-button (click)="avisoCerrado = true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-container>

    <!--content-->
    <div class="container h-100 bg-light" *ngIf="!(user$ | async)">
      <div class="row h-100 justify-content-center text-center align-items-center">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    </div>
    <div>
      <ng-content class="bg-light" *ngIf="user$ | async"></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
