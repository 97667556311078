import { registerLocaleData } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
registerLocaleData(localeMX, 'es-MX');

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BlockUIModule } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { UserDialogModule } from './shared/components/user-dialog/user-dialog.module';

@NgModule({
  imports: [
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    BlockUIModule.forRoot(),
    MatSnackBarModule,
    UserDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: !environment.production }),
  ],
  declarations: [AppComponent, AuthLayoutComponent, MainLayoutComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
