import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar) {}

  ngOnInit() {
    // Create the script tag, set the appropriate attributes
    var script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=visualization&callback=initMap&loading=async`;
    script.async = true;

    (window as any).initMap = function () {
      // JS API is loaded and available
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.showUpdateSnackBar();
      });
    }
  }

  private showUpdateSnackBar() {
    const snackBarRef = this.snackBar.open('¡Nueva versión disponible!', 'Recargar');
    snackBarRef.onAction().subscribe(() => {
      window.location.reload();
    });
  }
}
