import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the url to get the desired image size.
 */
@Pipe({ name: 'imageSize' })
export class ImageSizePipe implements PipeTransform {
  transform(baseUrl: string, size: string): string {
    if (!baseUrl) {
      return '';
    }
    // replace extension and previous size
    const extensionREGEX = /\.(\w{3,4})(?:$|\?)/i;
    const sizeREGEX = /_[0-9]{3}x[0-9]{3}.extension/i;
    return baseUrl
      .replace(extensionREGEX, '.extension?')
      .replace(sizeREGEX, '.extension')
      .replace('.extension', `_${size}x${size}.webp`);
  }
}
