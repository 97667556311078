import packageInfo from '../../package.json';

export const environment = {
  production: false,
  name: packageInfo.name,
  version: packageInfo.version,
  publicURL: 'https://ranvou.com/dev', // no debe terminar en diagonal
  appURL: 'https://ranvou-dev.firebaseapp.com',
  templateConceptosURL:
    'https://firebasestorage.googleapis.com/v0/b/ranvou-dev.appspot.com/o/plantillas%2Fplantilla_ranvou_v1.xlsm?alt=media&token=716281d8-627f-4098-a1b4-022b078bdaf5',
  firebaseConfig: {
    apiKey: 'AIzaSyD_SbBVPm_xADbtgHptmOEddQfLuhfjC5Q',
    authDomain: 'ranvou-dev.firebaseapp.com',
    databaseURL: 'https://ranvou-dev.firebaseio.com',
    projectId: 'ranvou-dev',
    storageBucket: 'ranvou-dev.appspot.com',
    messagingSenderId: '484851886661',
    appId: '1:484851886661:web:6f49be0285ed1580f8512c',
    measurementId: 'G-ZWV45V6EH1',
  },
  googleApiKey: 'AIzaSyCDrH6fAqGbVPFvUTzuJixa98ukK-VjSlE', // Google Maps Ranvou DEV Key - Open for debugginb
  algoliaAPPID: 'CHOTQP5X9D',
  algoliaAPIKEY: 'c96c02e6561ef9148ab7e9457336f66e',
  algoliaInsumosIndex: 'ranvou-dev-insumos-libre',
};
