<!-- dialog.component.html -->
<h3 mat-dialog-title>{{ data.titulo }}</h3>
<mat-dialog-content>
  {{ data.mensaje }}
  <input
    *ngIf="data.tipo === 'confirmar-eliminar'"
    [(ngModel)]="confirmacionIngresada"
    placeholder="Ingrese confirmación" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" *ngIf="data.tipo !== 'ok'">No</button>
  <button mat-button (click)="onYesClick()" color="primary">{{ data.tipo === 'ok' ? 'OK' : 'Sí' }}</button>
</mat-dialog-actions>
