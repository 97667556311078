import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, combineLatest, from } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Rol } from 'src/app/shared/models/rol-enum';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private auth: AuthService, public router: Router, public ngZone: NgZone) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data.roles as Rol[];

    return this.auth.usuarioSesion().pipe(
      take(1),
      switchMap(sesion => from(this.auth.getUserClaims()).pipe(
        map((claims) => sesion && sesion.usuario && roles.some(r => this.auth.tieneRol(r, claims)) ? true : false)
        , tap(tieneRol => {
          console.log('tiene algun rol?', roles, tieneRol);
          // main o login?
          if (!tieneRol) { this.router.navigate(['main']); }
        })
      ))
    );

  }
}
